import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction } from '../../../../utils/mathHelpers';
import { displayFractionWithPiInNumerator } from '../../../../utils/mathHelpers';


export default function TrigonometryRotations() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [2, 3, 4, 6, 8, 9, 10, 12, 14, 15, 16, 18, 20, 21, 22, 24];
    const plusminus = [0, 1];
    const practice_problems_num = 10;
    const generatedProblem = [];

    for (let step = 0; step < practice_problems_num; step++) {
      let a = nums[Math.floor(Math.random()*nums.length)];
      let b = nums[Math.floor(Math.random()*nums.length)];
      let a1 = displayFractionWithPiInNumerator(a,12);
      let b1 = displayFractionWithPiInNumerator(b,12);
      let c = plusminus[Math.floor(Math.random()*plusminus.length)];
      let t = a + b;
      let sign = '+';

      if(c == 1){
        t = a - b;
        sign = '-';
      }

      let d = t % 24;

      if(t < 0){
        d = 24 + t;
      }

      let e = displayFractionWithPiInNumerator(d,12);

      generatedProblem.push({
        key: `${step}-aa`,
        a: a,
        b: b,
        a1: a1,
        b1: b1,
        c: c,
        t: t,
        d: d,
        e: e,
        sign: sign
      });
    }

    let formattedProblemList = []
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: `$ ${equation.a1} ${equation.sign} ${equation.b1} $`,
      answer: `$ ${equation.e} $`
    }
  }

  function helpText() {
    return `
         $$ \\dfrac{3 \\pi}{2} - \\dfrac{11 \\pi}{6} $$
        \
         $$ = \\dfrac{6 \\cdot 3 \\pi}{6 \\cdot 2} - \\dfrac{2 \\cdot 11 \\pi}{2 \\cdot 6} $$
        \
         $$ = \\dfrac{18 \\pi}{12} - \\dfrac{22 \\pi}{12} $$
        \
         $$ = \\dfrac{18 \\pi - 22 \\pi}{12} $$
        \
         $$ = \\dfrac{-4 \\pi}{12} $$
        \
        $$ = \\dfrac{-1 \\cdot 4 \\cdot \\pi}{3 \\cdot 4} $$
        \
        $$ = \\dfrac{-1 \\pi}{3} $$
        \
        $$ = - \\dfrac{\\pi}{3} $$
        \
        $$ = 0 - \\dfrac{\\pi}{3} $$
        \
         $$ = 2 \\pi - \\dfrac{\\pi}{3} $$
        \
         $$ = \\dfrac{2 \\pi}{1} - \\dfrac{\\pi}{3} $$
        \
         $$ = \\dfrac{3 \\cdot 2 \\pi}{3 \\cdot 1} - \\dfrac{\\pi}{3} $$
        \
         $$ = \\dfrac{6 \\pi}{3} - \\dfrac{\\pi}{3} $$
        \
         $$ = \\dfrac{6 \\pi - \\pi}{3} $$
        \
        $$ = \\dfrac{5 \\pi}{3} $$
        \
      `
  }


  return (
    <div>
      <EquationLayout
        title={"Trigonometry Rotations"}
        instructions={"Compute the following by answering with the smallest positive angle in radians."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
        numCols={2}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Rotations Help", helpTextFunc: helpText}}
      />
    </div>
  );
}