import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';

export default function TrigonometryIdentities() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [1, 2, 3, 4, 5, 6];
    const generatedProblem = [];

    let matrix = [
      [1,1],
      ['cos(x)', 2],
      ['sin(x)', 3],
      ['tan(x)', 3/2],
      ['sec(x)', 1/2],
      ['csc(x)', 1/3],
      ['cot(x)', 2/3]
    ]

    let n1a = nums[Math.floor(Math.random()*nums.length)];
    let n1b = nums[Math.floor(Math.random()*nums.length)];
    let n2a = nums[Math.floor(Math.random()*nums.length)];
    let n2b = nums[Math.floor(Math.random()*nums.length)];
    let n3a = nums[Math.floor(Math.random()*nums.length)];
    let n3b = nums[Math.floor(Math.random()*nums.length)];
    let n4a = nums[Math.floor(Math.random()*nums.length)];
    let n4b = nums[Math.floor(Math.random()*nums.length)];
    let n5a = nums[Math.floor(Math.random()*nums.length)];
    let n5b = nums[Math.floor(Math.random()*nums.length)];
    let n6a = nums[Math.floor(Math.random()*nums.length)];
    let n6b = nums[Math.floor(Math.random()*nums.length)];

    let w = 88;

    let trig1a = matrix[n1a][0];
    let trig1b = matrix[n1b][0];
    let c1 = matrix[n1a][1]*matrix[n1b][1];

    let trig2a = matrix[n2a][0];
    let trig2b = matrix[n2b][0];
    let c2 = (matrix[n2a][1])/(matrix[n2b][1]);

    let trig3a = matrix[n3a][0];
    let trig3b = matrix[n3b][0];
    let c3 = (1/matrix[n3a][1])*matrix[n3b][1];

    let trig4a = matrix[n4a][0];
    let trig4b = matrix[n4b][0];
    let c4 = (1/matrix[n4a][1])/matrix[n4b][1];

    let trig5a = matrix[n5a][0];
    let trig5b = matrix[n5b][0];
    let c5 = (1/matrix[n5a][1])*(1/matrix[n5b][1]);

    let trig6a = matrix[n6a][0];
    let trig6b = matrix[n6b][0];
    let c6 = (1/matrix[n6a][1])/(1/matrix[n6b][1]);


    function findRowByColumnValue(matrix, columnIndex, targetValue) {
      for (let i = 0; i < matrix.length; i++) {
          if (matrix[i][columnIndex] === targetValue) {
              return i; // Return the row index where the value is found
          }
      }
      return -1; // Return -1 if the value is not found
  }
  
  // Searching for value `50` in column index `1`
  let ri1 = findRowByColumnValue(matrix, 1, c1);
  let ri2 = findRowByColumnValue(matrix, 1, c2);
  let ri3 = findRowByColumnValue(matrix, 1, c3);
  let ri4 = findRowByColumnValue(matrix, 1, c4);
  let ri5 = findRowByColumnValue(matrix, 1, c5);
  let ri6 = findRowByColumnValue(matrix, 1, c6);

  //while(ri1 === -1) {
  //  n2 = nums[Math.floor(Math.random()*nums.length)];
  //}
  
  let aa = matrix[n2a][1]
  let bb = matrix[n2b][1]
let attempts = 0;
let maxAttempts = 100; // Prevents infinite loops

while (ri1 === -1 && attempts < maxAttempts) {
    n1b = nums[Math.floor(Math.random() * nums.length)];
    trig1b = matrix[n1b][0];
    c1 = matrix[n1a][1] * matrix[n1b][1]; 
    ri1 = findRowByColumnValue(matrix, 1, c1); // Recalculate ri1
    attempts++;
}

while (ri2 === -1 && attempts < maxAttempts) {
  n2b = nums[Math.floor(Math.random() * nums.length)];
  trig2b = matrix[n2b][0];
  c2 = (matrix[n2a][1])/(matrix[n2b][1]); // Update c1
  bb = matrix[n2b][1];// Update c1
  ri2 = findRowByColumnValue(matrix, 1, c2); // Recalculate ri1
  attempts++;
}

while (ri3 === -1 && attempts < maxAttempts) {
  n3b = nums[Math.floor(Math.random() * nums.length)];
  trig3b = matrix[n3b][0];
  c3 = (1/matrix[n3a][1])*matrix[n3b][1]; // Update c1
  ri3 = findRowByColumnValue(matrix, 1, c3); // Recalculate ri1
  attempts++;
}

while (ri4 === -1 && attempts < maxAttempts) {
  n4b = nums[Math.floor(Math.random() * nums.length)];
  trig4b = matrix[n4b][0];
  c4 = (1/matrix[n4a][1])/matrix[n4b][1]; // Update c1
  ri4 = findRowByColumnValue(matrix, 1, c4); // Recalculate ri1
  attempts++;
}

while (ri5 === -1 && attempts < maxAttempts) {
  n5b = nums[Math.floor(Math.random() * nums.length)];
  trig5b = matrix[n5b][0];
  c5 = (1/matrix[n5a][1])*(1/matrix[n5b][1]); // Update c1
  ri5 = findRowByColumnValue(matrix, 1, c5); // Recalculate ri1
  attempts++;
}

while (ri6 === -1 && attempts < maxAttempts) {
  n6b = nums[Math.floor(Math.random() * nums.length)];
  trig6b = matrix[n6b][0];
  c6 = (1/matrix[n6a][1])/(1/matrix[n6b][1]); // Update c1
  ri6 = findRowByColumnValue(matrix, 1, c6); // Recalculate ri1
  attempts++;
}

if (ri1 === -1) {
   console.warn("No valid row found after max attempts, exiting loop.");
   ri1 = 0; // Fallback to avoid errors later
}

  let trig1 = matrix[ri1][0];
  let trig2 = matrix[ri2][0];
  let trig3 = matrix[ri3][0];
  let trig4 = matrix[ri4][0];
  let trig5 = matrix[ri5][0];
  let trig6 = matrix[ri6][0];
   

    generatedProblem.push({
      key: "1-csode",
      c1: c1,
      result: `$ ${trig1} $`,
      format: `$ ${trig1a} \\cdot ${trig1b} $`
    });

    generatedProblem.push({
      key: "2-csode",
      w: w,
      result: `$ ${trig2} $`,
      format: `$ \\dfrac{${trig2a}}{${trig2b}} $`
    });

    generatedProblem.push({
      key: "3-csode",
      w: w,
      result: `$ ${trig3} $`,
      format: `$ \\dfrac{1}{${trig3a}} \\cdot ${trig3b} $`
    });

    generatedProblem.push({
      key: "4-csode",
      w: w,
      result: `$ ${trig4} $`,
      format: `$ \\dfrac{\\biggl( \\dfrac{1}{${trig4a}} \\biggr)}{${trig4b}} $`
    });

    generatedProblem.push({
      key: "5-csode",
      w: w,
      result: `$ ${trig5} $`,
      format: `$ \\dfrac{1}{${trig5a}} \\cdot \\dfrac{1}{${trig5b}} $`
    });

    generatedProblem.push({
      key: "6-csode",
      w: w,
      result: `$ ${trig6} $`,
      format: `$ \\dfrac{\\biggl( \\dfrac{1}{${trig6a}} \\biggr)}{\\biggl( \\dfrac{1}{${trig6b}} \\biggr)} $`
    });

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: equation.format,
      answer: equation.result
    }
  }

  function helpText() {
    return `
        $$ \\dfrac{\\biggl( \\dfrac{1}{csc(x)} \\biggr)}{\\biggl( \\dfrac{1}{cot(x)} \\biggr)} $$
        \
        $$ = \\dfrac{1}{csc(x)} \\div \\dfrac{1}{cot(x)} $$
        \
        $$ = \\dfrac{1}{csc(x)} \\cdot \\dfrac{cot(x)}{1} $$
        \
        $$ = \\dfrac{cot(x)}{csc(x)} $$
        \
        $$ = cot(x) \\div csc(x) $$
        \
        $$ = \\dfrac{cos(x)}{sin(x)} \\div \\dfrac{1}{sin(x)} $$
        \
        $$ = \\dfrac{cos(x)}{sin(x)} \\cdot \\dfrac{sin(x)}{1} $$
        \
        $$ = \\dfrac{cos(x) \\cdot sin(x)}{sin(x) \\cdot 1} $$
        \
        $$ = \\dfrac{cos(x)}{1} $$
        \
        $$ = cos(x) $$
      `
  }

  return (
    <div>
      <EquationLayout
        title={"Trigonometry Identities"}
        instructions={"Simplify the expressions using the various reciprocal and quotient definitions of the 6 trigonometric functions."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
        numCols={2}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Identities Help", helpTextFunc: helpText}}
      />
    </div>
  );
}