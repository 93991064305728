import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { trigTable } from '../../../../utils/trigonometryHelper';
import { displayFractionWithPiInNumerator } from '../../../../utils/mathHelpers';
import { displayFraction } from '../../../../utils/mathHelpers';

export default function TrigonometryDoubleAngleFormulas() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const nums = [9, 11, 13, 15];
    const generatedProblem = [];

    let n1 = nums[Math.floor(Math.random()*nums.length)];
    let x1 = trigTable[n1][2];
    let top1 = trigTable[n1][3];
    let a1 = top1*(4-top1);
    let b1 = displayFractionWithPiInNumerator(x1, 6);
    let sign1 = ' ';

    if ((x1 % 12) > 6 && (x1 % 12) < 12){
      sign1 = '-'
    }

    generatedProblem.push({
      key: "1-tdaf",
      x1: x1,
      top1: top1,
      a1: a1,
      b1: b1,
      result: `$ ${sign1} \\dfrac{\\sqrt{${a1}}}{2} $`,
      format: `$ sin \\left( ${b1} \\right) $`
    });



    let n2 = nums[Math.floor(Math.random()*nums.length)];
    let x2 = trigTable[n2][2];
    let top2 = trigTable[n2][3];
    let a2 = top2 - 2;
    let b2 = displayFractionWithPiInNumerator(x2, 6);
    let sign2 = ' ';

    if ((x2 % 12) > 3 && (x2 % 12) < 9){
      sign2 = '-'
    }

    generatedProblem.push({
      key: "2-tdaf",
      x2: x2,
      top2: top2,
      a2: a2,
      b2: b2,
      result: `$ \\dfrac{${a2}}{2} $`,
      format: `$ cos \\left( ${b2} \\right) $`
    });


    let n3 = nums[Math.floor(Math.random()*nums.length)];
    let x3 = trigTable[n3][2];
    let top3 = trigTable[n3][4];
    let b3 = displayFractionWithPiInNumerator(x3, 6);
    let sign3 = ' ';

    if (n3 == 11 || n3 == 15){
      sign3 = '-'
    }

    generatedProblem.push({
      key: "3-tdaf",
      x3: x3,
      top3: top3,
      b3: b3,
      result: `$ ${sign3} \\sqrt{ 3 } $`,
      format: `$ tan \\left( ${b3} \\right) $`
    });

    
    let n4 = nums[Math.floor(Math.random()*nums.length)];
    let x4 = trigTable[n4][2];
    let top4 = trigTable[n4][3];
    let a4 = top4*(4-top4);
    let b4 = displayFractionWithPiInNumerator(x4, 6);
    let sign4 = ' ';

    if ((x4 % 12) > 6 && (x4 % 12) < 12){
      sign4 = '-'
    }

    generatedProblem.push({
      key: "4-tdaf",
      x4: x4,
      top4: top4,
      a4: a4,
      b4: b4,
      result: `$ ${sign4} \\dfrac{2}{\\sqrt{${a4}}} $`,
      format: `$ csc \\left( ${b4} \\right) $`
    });


    let n5 = nums[Math.floor(Math.random()*nums.length)];
    let x5 = trigTable[n5][2];
    let top5 = trigTable[n5][3];
    let a5 = top5 - 2;
    let b5 = displayFractionWithPiInNumerator(x5, 6);
    let sign5 = ' ';

    if ((x5 % 12) > 3 && (x5 % 12) < 9){
      sign5 = '-'
    }

    generatedProblem.push({
      key: "5-tdaf",
      x5: x5,
      top5: top5,
      a5: a5,
      b5: b5,
      result: `$ ${sign5} 2 $`,
      format: `$ sec \\left( ${b5} \\right) $`
    });


    let n6 = nums[Math.floor(Math.random()*nums.length)];
    let x6 = trigTable[n6][2];
    let top6 = trigTable[n6][4];
    let b6 = displayFractionWithPiInNumerator(x6, 6);
    let sign6 = ' ';

    if (n6 == 11 || n6 == 15){
      sign6 = '-'
    }

    generatedProblem.push({
      key: "6-tdaf",
      x6: x6,
      top6: top6,
      b6: b6,
      result: `$ ${sign6} \\dfrac{1}{\\sqrt{ 3 }} $`,
      format: `$ cot \\left( ${b6} \\right) $`
    });


    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: equation.format,
      answer: equation.result
    }
  }

  function helpText() {
    return `
        $$ cos \\left( 2 \\theta \\right) = cos^{2} (\\theta) - sin^{2} (\\theta) $$
        \
        $$ sin \\left( 2 \\theta \\right) = 2 \\cdot cos(\\theta) \\cdot sin(\\theta) $$
        \
        $$ tan \\left( 2 \\theta \\right) = \\dfrac{2 \\cdot tan(\\theta)}{1 - tan^{2}(\\theta)} $$
        \
        $$ csc \\left( \\dfrac{11 \\pi}{3} \\right) $$
        \
        $$ = \\dfrac{1}{sin \\left( \\dfrac{11 \\pi}{3} \\right)} $$
        \
        $$ = \\dfrac{1}{2 \\cdot cos \\left( \\dfrac{11 \\pi}{3} \\cdot \\dfrac{1}{2} \\right) \\cdot sin \\left(  \\dfrac{11 \\pi}{3} \\cdot \\dfrac{1}{2} \\right)} $$
        \
        $$ = \\dfrac{1}{2 \\cdot cos \\left( \\dfrac{11 \\pi}{6} \\right) \\cdot sin \\left(  \\dfrac{11 \\pi}{6} \\right)} $$
        \
        $$ = \\dfrac{1}{2 \\cdot \\left( \\dfrac{\\sqrt{3}}{2} \\right) \\cdot \\left(  \\dfrac{-1}{2} \\right)} $$
        \
        $$ = \\dfrac{1}{2 \\cdot \\left( \\dfrac{- \\sqrt{3}}{4} \\right)} $$
        \
        $$ = \\dfrac{1}{\\left( \\dfrac{- 2 \\sqrt{3}}{4} \\right)} $$
        \
        $$ = 1 \\div \\dfrac{-2 \\sqrt{3}}{4} $$
        \
        $$ = 1 \\cdot \\dfrac{4}{-2 \\sqrt{3}} $$
        \
        $$ = \\dfrac{4}{-2 \\sqrt{3}} $$
        \
        $$ = \\dfrac{2 \\cdot 2}{-1 \\cdot 2 \\sqrt{3}} $$
        \
        $$ = \\dfrac{2}{-1 \\sqrt{3}} $$
        \
        $$ = - \\dfrac{2}{\\sqrt{3}} $$
      `
  }

  return (
    <div>
      <EquationLayout
        title={"Trigonometry Double Angle Formulas"}
        instructions={"Compute the following using the Double Angle Formulas."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
        numCols={2}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Double Angle Formulas Help", helpTextFunc: helpText}}
      />
    </div>
  );
}
