import React, { useState } from 'react';

import LinearOneStepA from './LinearOneStepA';
import LinearOneStepB from './LinearOneStepB';
import LinearTwoStepA from './LinearTwoStepA';
import LinearTwoStepB from './LinearTwoStepB';
import LinearTwoStepC from './LinearTwoStepC';
import LinearTwoStepD from './LinearTwoStepD';
import LinearTwoStepLikeTermsA from './LinearTwoStepLikeTermsA';
import LinearTwoStepLikeTermsB from './LinearTwoStepLikeTermsB';
import LinearThreeStepA from './LinearThreeStepA';
import LinearThreeStepLikeTermsA from './LinearThreeStepLikeTermsA';
import LinearFourStepLikeTermsA from './LinearFourStepLikeTermsA';
import LinearFourStepLikeTermsB from './LinearFourStepLikeTermsB';
import LinearSystemOfEquations2x2 from './LinearSystemOfEquations2x2';
import LinearSystemOfEquations3x3 from './LinearSystemOfEquations3x3';
import LinearFeatures from './LinearFeatures';
import LinearTwoPoints from './LinearTwoPoints';
import LinearFunctions from './LinearFunctions';

import Button from 'react-bootstrap/Button';

const ONE_STEP_A = "one_step_a";
const ONE_STEP_B = "one_step_b";
const TWO_STEP_A = "two_step_a";
const TWO_STEP_B = "two_step_b";
const TWO_STEP_C = "two_step_c";
const TWO_STEP_D = "two_step_d";
const TWO_STEP_LIKE_TERMS_A = "two_step_like_terms_a";
const TWO_STEP_LIKE_TERMS_B = "two_step_like_terms_b";
const THREE_STEP_A = "three_step_a";
const THREE_STEP_LIKE_TERMS_A = "three_step_like_terms_a";
const FOUR_STEP_LIKE_TERMS_A = "four_step_like_terms_a";
const FOUR_STEP_LIKE_TERMS_B = "four_step_like_terms_b";
const SYSTEM_OF_EQUATIONS_2X2 = "system_of_equations_2x2";
const SYSTEM_OF_EQUATIONS_3X3 = "system_of_equations_3x3";
const FEATURES = "features";
const TWO_POINTS = "two_points";
const FUNCTIONS = "functions";

const FUNCTION_TYPES = [
  ONE_STEP_A, ONE_STEP_B,
  TWO_STEP_A, TWO_STEP_B, TWO_STEP_C, TWO_STEP_D, TWO_STEP_LIKE_TERMS_A, TWO_STEP_LIKE_TERMS_B,
  THREE_STEP_A, THREE_STEP_LIKE_TERMS_A, FOUR_STEP_LIKE_TERMS_A, FOUR_STEP_LIKE_TERMS_B,
  SYSTEM_OF_EQUATIONS_2X2, SYSTEM_OF_EQUATIONS_3X3, FEATURES, TWO_POINTS, FUNCTIONS
];

const functionTypeDisplayNames = {
  "one_step_a": "One Step A",
  "one_step_b": "One Step B",
  "two_step_a": "Two Step A",
  "two_step_b": "Two Step B",
  "two_step_c": "Two Step C",
  "two_step_d": "Two Step D",
  "two_step_like_terms_a": "Two Step Like Terms A",
  "two_step_like_terms_b": "Two Step Like Terms B",
  "three_step_a": "Three Step A",
  "three_step_like_terms_a": "Three Step Like Terms A",
  "four_step_like_terms_a": "Four Step Like Terms A",
  "four_step_like_terms_b": "Four Step Like Terms B",
  "system_of_equations_2x2": "System Of Equations 2x2",
  "system_of_equations_3x3": "System Of Equations 3x3",
  "features": "Features",
  "two_points": "Two Points",
  "functions": "Functions"
}

export default function Linear() {
  const [selectedFunctionType, setSelectedFunctionType] = useState(ONE_STEP_A);

  function displayListOfFunctionTypeTabs() {
    return (
      <span>
        {
          FUNCTION_TYPES.map(tab =>
            <span key={tab}>
              <Button
                variant={selectedFunctionType === tab ? "primary" : "outline-primary"}
                onClick={() => setSelectedFunctionType(tab)}
              >
                {functionTypeDisplayNames[tab]}
              </Button>
            </span>
          )
        }
      </span>
    )
  }

  function displayCurrentFunctionTypeContent() {
    switch(selectedFunctionType) {
      case ONE_STEP_A: {
        return <LinearOneStepA />;
      }
      case ONE_STEP_B: {
        return <LinearOneStepB />;
      }
      case TWO_STEP_A: {
        return <LinearTwoStepA />;
      }
      case TWO_STEP_B: {
        return <LinearTwoStepB />;
      }
      case TWO_STEP_C: {
        return <LinearTwoStepC />;
      }
      case TWO_STEP_D: {
        return <LinearTwoStepD />;
      }
      case TWO_STEP_LIKE_TERMS_A: {
        return <LinearTwoStepLikeTermsA />;
      }
      case TWO_STEP_LIKE_TERMS_B: {
        return <LinearTwoStepLikeTermsB />;
      }
      case THREE_STEP_A: {
        return <LinearThreeStepA />;
      }
      case THREE_STEP_LIKE_TERMS_A: {
        return <LinearThreeStepLikeTermsA />;
      }
      case FOUR_STEP_LIKE_TERMS_A: {
        return <LinearFourStepLikeTermsA />;
      }
      case FOUR_STEP_LIKE_TERMS_B: {
        return <LinearFourStepLikeTermsB />;
      }
      case SYSTEM_OF_EQUATIONS_2X2: {
        return <LinearSystemOfEquations2x2 />;
      }
      case SYSTEM_OF_EQUATIONS_3X3: {
        return <LinearSystemOfEquations3x3 />;
      }
      case FEATURES: {
        return <LinearFeatures />;
      }
      case TWO_POINTS: {
        return <LinearTwoPoints />;
      }
      case FUNCTIONS: {
        return <LinearFunctions />;
      }
      default:
        return <div>POOP</div>;
    }
  }

  return (
    <div>
      <p>Straight lines and their features. Learn where they intersect and the intercept through their equations.</p>
      {displayListOfFunctionTypeTabs()}
      {displayCurrentFunctionTypeContent()}
    </div>
  )
}