import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction } from '../../../../utils/mathHelpers';

export default function ArithmeticFractionsOfFractions() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);



  function getUniqueNumbers(count, nums) {
    const selected = new Set();
    while (selected.size < count) {
      selected.add(nums[Math.floor(Math.random() * nums.length)]);
    }
    return [...selected];
  }
  
  function equationMaker() {
    const nums = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const generatedProblem = [];
  
    // Ensure each set of numbers for a problem is unique
    let [n1, n2] = getUniqueNumbers(2, nums);
    let [n3, n4, n5] = getUniqueNumbers(3, nums);
    let [n6, n7, n8] = getUniqueNumbers(3, nums);
    let [n9, n10, n11, n12] = getUniqueNumbers(4, nums);
    let [n13, n14, n15] = getUniqueNumbers(3, nums);
    let [n16, n17, n18] = getUniqueNumbers(3, nums);
    let [n19, n20, n21, n22] = getUniqueNumbers(4, nums);
    let [n23, n24] = getUniqueNumbers(2, nums);
  
    let r1 = displayFraction(n2, n1);
    let r2 = displayFraction(n3 * n5, n4);
    let r3 = displayFraction(n6, n7 * n8);
    let r4 = displayFraction(n9 * n12, n10 * n11);
    let r5 = displayFraction(n24, n23);
    let r6 = displayFraction(n13 * n15, n14);
    let r7 = displayFraction(n16, n17 * n18);
    let r8 = displayFraction(n19 * n22, n20 * n21);
  
    generatedProblem.push({
      key: "1-afof",
      n1, n2,
      result: `$ ${r1} $`,
      format: <span>{`$ \\dfrac{1}{\\frac{${n1}}{${n2}}} $`}</span>
    });
  
    generatedProblem.push({
      key: "2-afof",
      n3, n4, n5,
      result: `$ ${r2} $`,
      format: <span>{`$ \\dfrac{${n3}}{\\frac{${n4}}{${n5}}} $`}</span>
    });
  
    generatedProblem.push({
      key: "3-afof",
      n6, n7, n8,
      result: `$ ${r3} $`,
      format: <span>{`$ \\dfrac{\\frac{${n6}}{${n7}}}{${n8}} $`}</span>
    });
  
    generatedProblem.push({
      key: "4-afof",
      n9, n10, n11, n12,
      result: `$ ${r4} $`,
      format: <span>{`$ \\dfrac{\\frac{${n9}}{${n10}}}{\\frac{${n11}}{${n12}}} $`}</span>
    });
  
    generatedProblem.push({
      key: "5-afof",
      n23, n24,
      result: `$ ${r5} $`,
      format: <span>{`$ \\dfrac{1}{\\frac{${n23}}{${n24}}} $`}</span>
    });
  
    generatedProblem.push({
      key: "6-afof",
      n13, n14, n15,
      result: `$ ${r6} $`,
      format: <span>{`$ \\dfrac{${n13}}{\\frac{${n14}}{${n15}}} $`}</span>
    });
  
    generatedProblem.push({
      key: "7-afof",
      n16, n17, n18,
      result: `$ ${r7} $`,
      format: <span>{`$ \\dfrac{\\frac{${n16}}{${n17}}}{${n18}} $`}</span>
    });
  
    generatedProblem.push({
      key: "8-afof",
      n19, n20, n21, n22,
      result: `$ ${r8} $`,
      format: <span>{`$ \\dfrac{\\frac{${n19}}{${n20}}}{\\frac{${n21}}{${n22}}} $`}</span>
    });
  
    let formattedProblemList = generatedProblem.map(equationFormatter);
    setFormattedProblems(formattedProblemList);

  }
  

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: equation.format,
      answer: equation.result
    }
  }

  function helpText() {
    return `
      $$ \\dfrac{\\frac{7}{2}}{3} $$    
      \
      $$ = \\dfrac{7}{2} \\div 3 $$
      \
      $$ = \\dfrac{7}{2} \\div \\dfrac{3}{1} $$
      \
      $$ = \\dfrac{7}{2} \\cdot \\dfrac{1}{3} $$
      \
      $$ = \\dfrac{7 \\cdot 1}{2 \\cdot 3} $$
      \
      $$ = \\dfrac{7}{6} $$
      `
  }

  return (
    <div>
      
      <EquationLayout
      title={"Arithmetic Fractions of Fractions"}
      instructions={"Compute the following."}
      displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
        numCols={2}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Fractions of Fractions Help", helpTextFunc: helpText}}
      />
    </div>
  );
}