import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { trigTable } from '../../../../utils/trigonometryHelper';

export default function TrigonometryHalfAngleFormulas() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    const cosnums = [1, 2, 6, 7, 9, 10, 14, 15];
    const generatedProblem = [];

    let n1 = cosnums[Math.floor(Math.random()*cosnums.length)];
    let x1 = trigTable[n1][2];
    let top1 = trigTable[n1][3];
    let sign1a = trigTable[n1][7];
    let sign1b = '-';
    let sign1c = ' ';

    if (sign1a == '-'){
      sign1b = '+'
    }

    if (x1 > 24 && x1 < 48){
      sign1c = '-'
    }

    generatedProblem.push({
      key: "1-csode",
      x1: x1,
      top1, top1,
      result: `$ ${sign1c} \\dfrac{\\sqrt{2 ${sign1b} \\sqrt{${top1}}}}{2} $`,
      format: `$ sin \\left( \\dfrac{${x1} \\pi}{24} \\right) $`
    });

    let n2 = cosnums[Math.floor(Math.random()*cosnums.length)];
    let x2 = trigTable[n2][2];
    let top2 = trigTable[n2][3];
    let sign2a = trigTable[n2][7];
    let sign2b = '+';
    let sign2c = ' ';

    if (sign2a == '-'){
      sign2b = '-'
    }

    if (x2 > 12 && x2 < 36){
      sign2c = '-'
    }

    generatedProblem.push({
      key: "1-csode",
      x2: x2,
      top2, top2,
      result: `$ ${sign2c} \\dfrac{\\sqrt{2 ${sign2b} \\sqrt{${top2}}}}{2} $`,
      format: `$ cos \\left( \\dfrac{${x2} \\pi}{24} \\right) $`
    });

    let n3 = cosnums[Math.floor(Math.random()*cosnums.length)];
    let x3 = trigTable[n3][2];
    let top3 = trigTable[n3][3];
    let sign3a = trigTable[n3][7];
    let y3 = 4 - top3;
    let sign3b = '-';
    let sign3c = ' ';

    if (sign3a == '-'){
      sign3b = '+'
    }

    if (x3 > 12 && x3 < 24){
      sign3c = '-'
    }

    let denominator = y3 === 1 ? '' : `\\sqrt{${y3}}`;
    let fractionLine = y3 === 1 ? '' : '\\dfrac{';

generatedProblem.push({
  key: "1-csode",
  x3: x3,
  top3, top3,
  result: `$ ${sign3c} ${fractionLine}(2 ${sign3b} \\sqrt{${top3}})${y3 === 1 ? '' : `}{${denominator}}`} $`,
  format: `$ tan \\left( \\dfrac{${x3} \\pi}{24} \\right) $`
});

    let n5 = cosnums[Math.floor(Math.random()*cosnums.length)];
    let x5 = trigTable[n5][2];
    let top5 = trigTable[n5][3];
    let sign5a = trigTable[n5][7];
    let sign5b = '-';
    let sign5c = ' ';

    if (sign5a == '-'){
      sign5b = '+'
    }

    if (x5 > 24 && x5 < 48){
      sign5c = '-'
    }

    generatedProblem.push({
      key: "1-csode",
      x5: x5,
      top5, top5,
      result: `$ ${sign5c} \\dfrac{2}{\\sqrt{2 ${sign5b} \\sqrt{${top5}}}} $`,
      format: `$ csc \\left( \\dfrac{${x5} \\pi}{24} \\right) $`
    });

    let n4 = cosnums[Math.floor(Math.random()*cosnums.length)];
    let x4 = trigTable[n4][2];
    let top4 = trigTable[n4][3];
    let sign4a = trigTable[n4][7];
    let sign4b = '+';
    let sign4c = ' ';

    if (sign4a == '-'){
      sign4b = '-'
    }

    if (x4 > 12 && x4 < 36){
      sign4c = '-'
    }

    generatedProblem.push({
      key: "1-csode",
      x4: x4,
      top4, top4,
      result: `$ ${sign4c} \\dfrac{2}{\\sqrt{2 ${sign4b} \\sqrt{${top4}}}} $`,
      format: `$ sec \\left( \\dfrac{${x4} \\pi}{24} \\right) $`
    });

    let n6 = cosnums[Math.floor(Math.random()*cosnums.length)];
    let x6 = trigTable[n6][2];
    let top6 = trigTable[n6][3];
    let sign6a = trigTable[n6][7];
    let y6 = 4 - top6;
    let sign6b = '-';
    let sign6c = ' ';

    if (sign6a == '-'){
      sign6b = '+'
    }

    if (x6 > 12 && x6 < 24){
      sign6c = '-'
    }

    let numerator = y6 === 1 ? '1' : `\\sqrt{${y6}}`;

    generatedProblem.push({
      key: "1-csode",
      x6: x6,
      top6, top6,
      result: `$ ${sign6c} \\dfrac{${numerator}}{2 ${sign6b} \\sqrt{${top6}}} $`,
      format: `$ cot \\left( \\dfrac{${x6} \\pi}{24} \\right) $`
    });
    

    let formattedProblemList = [];
    generatedProblem.forEach(problem => {
      formattedProblemList.push(equationFormatter(problem))
    })
    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter(equation) {
    return {
      key: equation.key,
      equation: equation.format,
      answer: equation.result
    }
  }

  function helpText() {
    return `
        $$ cos \\left( \\dfrac{\\theta}{2} \\right) = \\pm \\sqrt{\\dfrac{1 + cos(\\theta)}{2}} $$
        \
        $$ sin \\left( \\dfrac{\\theta}{2} \\right) = \\pm \\sqrt{\\dfrac{1 - cos(\\theta)}{2}} $$
        \
        $$ tan \\left( \\dfrac{\\theta}{2} \\right) = \\dfrac{1 - cos(\\theta)}{sin(\\theta)} $$
        \
        $$ tan \\left( \\dfrac{22 \\pi}{24} \\right) $$
        \
        $$ = tan \\left( \\dfrac{2 \\cdot 11 \\cdot \\pi}{2 \\cdot 12} \\right) $$
        \
        $$ = tan \\left( \\dfrac{11 \\pi}{12} \\right) $$
        \
        $$ = \\dfrac{1 - cos \\left( 2 \\cdot \\dfrac{11 \\pi}{12} \\right)}{sin \\left( 2 \\cdot \\dfrac{11 \\pi}{12} \\right)} $$
        \
        $$ = \\dfrac{1 - cos \\left( \\dfrac{2 \\cdot 11 \\pi}{2 \\cdot 6} \\right)}{sin \\left( \\dfrac{2 \\cdot 11 \\pi}{2 \\cdot 6} \\right)} $$
        \
        $$ = \\dfrac{1 - cos \\left( \\dfrac{11 \\pi}{6} \\right)}{sin \\left( \\dfrac{11 \\pi}{6} \\right)} $$
        \
        $$ = \\dfrac{1 - \\left( \\dfrac{\\sqrt{3}}{2} \\right)}{\\left( - \\dfrac{1}{2} \\right)} $$
        \
        $$ = \\dfrac{1 - \\left( \\dfrac{\\sqrt{3}}{2} \\right)}{\\left( - \\dfrac{1}{2} \\right)} \\cdot \\dfrac{2}{2} $$
        \
        $$ = \\dfrac{1 \\cdot 2 - \\left( \\dfrac{\\sqrt{3} \\cdot 2}{2} \\right)}{\\left( - \\dfrac{1 \\cdot 2}{2} \\right)} $$
        \
        $$ = \\dfrac{2 - \\sqrt{3}}{- 1} $$
        \
        $$ = - \\dfrac{2 - \\sqrt{3}}{1} $$
        \
        $$ = - (2 - \\sqrt{3}) $$
        \
        $$ = \\sqrt{3} - 2 $$
      `
  }

  return (
    <div>
      <EquationLayout
        title={"Trigonometry Half Angle Formulas"}
        instructions={"Compute the following using the Half Angle Formulas."}
        displayAnswerInline={false}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
        numCols={2}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Half Angle Formulas Help", helpTextFunc: helpText}}
      />
    </div>
  );
}