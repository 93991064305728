import React, { useState } from 'react';

import TrigonometryRightTriangles from './TrigonometryRightTriangles';
import TrigonometryGenericTriangles from './TrigonometryGenericTriangles';
import TrigonometryCircles from './TrigonometryCircles';
import TrigonometryRotations from './TrigonometryRotations';
import TrigonometryUnitCircleA from './TrigonometryUnitCircleA';
import TrigonometryUnitCircleB from './TrigonometryUnitCircleB';
import TrigonometryWaves from './TrigonometryWaves';
import TrigonometryIdentities from './TrigonometryIdentities';
import TrigonometryHalfAngleFormulas from './TrigonometryHalfAngleFormulas';
import TrigonometryDoubleAngleFormulas from './TrigonometryDoubleAngleFormulas';
import Button from 'react-bootstrap/Button';

const RIGHT_TRIANGLES = "right_triangles"
const GENERIC_TRIANGLES = "generic_triangles"
const CIRCLES = "circles"
const ROTATIONS = "rotations"
const UNIT_CIRCLE_A = "unit_circle_a"
const UNIT_CIRCLE_B = "unit_circle_b"
const WAVES = "waves"
const IDENTITIES = "identities"
const HALF_ANGLE_FORMULAS = "half_angle_formulas"
const DOUBLE_ANGLE_FORMULAS = "double_angle_formulas"
const FUNCTION_TYPES = [RIGHT_TRIANGLES, GENERIC_TRIANGLES, CIRCLES, ROTATIONS, UNIT_CIRCLE_A, UNIT_CIRCLE_B, 
  WAVES, IDENTITIES, HALF_ANGLE_FORMULAS, DOUBLE_ANGLE_FORMULAS];

const functionTypeDisplayNames = {
  "right_triangles": "Right Triangles",
  "generic_triangles": "Generic Triangles",
  "circles": "Circles",
  "rotations": "Rotations",
  "unit_circle_a": "Unit Circle A",
  "unit_circle_b": "Unit Circle B",
  "waves": "Waves",
  "identities": "Identities",
  "half_angle_formulas": "Half Angle Formulas",
  "double_angle_formulas": "Double Angle Formulas"
}

export default function Trigonometry() {
  const [selectedFunctionType, setSelectedFunctionType] = useState(RIGHT_TRIANGLES);

  function displayListOfFunctionTypeTabs() {
    return (
      <span>
        {
          FUNCTION_TYPES.map(tab =>
            <span key={tab}>
              <Button
                variant={selectedFunctionType === tab ? "primary" : "outline-primary"}
                onClick={() => setSelectedFunctionType(tab)}
              >
                {functionTypeDisplayNames[tab]}
              </Button>
            </span>
          )
        }
      </span>
    )
  }

  function displayCurrentFunctionTypeContent() {
    switch(selectedFunctionType) {
      case RIGHT_TRIANGLES: {
        return <TrigonometryRightTriangles />;
      }
      case GENERIC_TRIANGLES: {
        return <TrigonometryGenericTriangles />;
      }
      case CIRCLES: {
        return <TrigonometryCircles />;
      }
      case ROTATIONS: {
        return <TrigonometryRotations />;
      }
      case UNIT_CIRCLE_A: {
        return <TrigonometryUnitCircleA />;
      }
      case UNIT_CIRCLE_B: {
        return <TrigonometryUnitCircleB />;
      }
      case WAVES: {
        return <TrigonometryWaves />;
      }
      case IDENTITIES: {
        return <TrigonometryIdentities />;
      }
      case HALF_ANGLE_FORMULAS: {
        return <TrigonometryHalfAngleFormulas />;
      }
      case DOUBLE_ANGLE_FORMULAS: {
        return <TrigonometryDoubleAngleFormulas />;
      }
      default:
        return <div>POOP</div>;
    }
  }

  return (
    <div>
      <p>Circles + Triangles = Waves.</p>
      {displayListOfFunctionTypeTabs()}
      {displayCurrentFunctionTypeContent()}
    </div>
  )
}