import React, { useState, useEffect } from 'react';
import EquationLayout from '../../../EquationDisplay/EquationLayout';
import GenerateButtons from '../../../GenerateButtons';
import { displayFraction, randomIntFromInterval } from '../../../../utils/mathHelpers';

export default function TrigonometryWaves() {
  const [viewAnswers, setViewAnswers] = useState(false);
  const [formattedProblems, setFormattedProblems] = useState([]);

  useEffect(() => {
    equationMaker();
  }, []);

  function equationMaker() {
    let formattedProblemList = [
      equationFormatter1(),
      equationFormatter2(),
      equationFormatter3(),
      equationFormatter4(),
      equationFormatter5(),
      equationFormatter6()
    ]

    setFormattedProblems(formattedProblemList)
  }

  function equationFormatter1() {
    let n1 = randomIntFromInterval(2, 9)
    let n2 = randomIntFromInterval(2, 9)
    let n3 = randomIntFromInterval(2, 9)
    let n4 = randomIntFromInterval(2, 9)

    let f1 = displayFraction(n2, 2)
    let f2 = displayFraction(2, n2)
    let f3 = displayFraction(1, 2*n2)
    let f4 = displayFraction(n3, n2)

    let s1 = randomIntFromInterval(0, 1)
    let s2 = randomIntFromInterval(0, 1)
    let sign1 = "+"
    let sign2 = "+"
    let mid = n4
    let ps = "Left"

    if(s1 == 1){
        sign1 = "-"
        ps = "Right"
    }

    if(s2 == 1){
        sign2 = "-"
        mid = -n4
    }

    let answerToDisplay = (
      <span>
        <span>{`$ \\text{Amplitude} = ${n1} $`}</span>
        <br/>
        <span>{`$ \\text{Midline} = ${mid} $`}</span>
        <br/>
        <span>{`$ \\text{Frequency} = ${f1} $`}</span>
        <br/>
        <span>{`$ \\text{Period} = T = ${f2} $`}</span>
        <br/>
        <span>{`$ \\text{Quarter Period} = \\dfrac{T}{4} = ${f3} $`}</span>
        <br/>
        <span>{`$ \\text{Phase Shift} = \\text{${ps}} \\; ${f4} $`}</span>
        <br/>
      </span>
    )

    return {
      key: '1-tw',
      equation: `$ y = ${n1} \\text{sin}(${n2} \\pi x ${sign1} ${n3} \\pi) ${sign2} ${n4} $`,
      answer: answerToDisplay,
    }
  }

  function equationFormatter2() {
    let n1 = randomIntFromInterval(2, 9)
    let n2 = randomIntFromInterval(2, 9)
    let n3 = randomIntFromInterval(2, 9)
    let n4 = randomIntFromInterval(2, 9)

    let f1 = displayFraction(n2, 2)
    let f2 = displayFraction(2, n2)
    let f3 = displayFraction(1, 2*n2)
    let f4 = displayFraction(n3, n2)

    let s1 = randomIntFromInterval(0, 1)
    let s2 = randomIntFromInterval(0, 1)
    let sign1 = "+"
    let sign2 = "+"
    let mid = n4
    let ps = "Left"

    if(s1 == 1){
        sign1 = "-"
        ps = "Right"
    }

    if(s2 == 1){
        sign2 = "-"
        mid = -n4
    }

    let answerToDisplay = (
      <span>
        <span>{`$ \\text{Amplitude} = ${n1} $`}</span>
        <br/>
        <span>{`$ \\text{Midline} = ${mid} $`}</span>
        <br/>
        <span>{`$ \\text{Frequency} = ${f1} $`}</span>
        <br/>
        <span>{`$ \\text{Period} = T = ${f2} $`}</span>
        <br/>
        <span>{`$ \\text{Quarter Period} = \\dfrac{T}{4} = ${f3} $`}</span>
        <br/>
        <span>{`$ \\text{Phase Shift} = \\text{${ps}} \\; ${f4} $`}</span>
        <br/>
      </span>
    )

    return {
      key: '2-tw',
      equation: `$ y = ${n1} \\text{cos}(${n2} \\pi x ${sign1} ${n3} \\pi) ${sign2} ${n4} $`,
      answer: answerToDisplay,
    }
  }

  function equationFormatter3() {
    let n1 = randomIntFromInterval(2, 9)
    let n2 = randomIntFromInterval(2, 9)
    let n3 = randomIntFromInterval(2, 9)
    let n4 = randomIntFromInterval(2, 9)

    let f1 = displayFraction(n2, 2)
    let f2 = displayFraction(2, n2)
    let f3 = displayFraction(1, 2*n2)
    let f4 = displayFraction(n3, n2)

    let s1 = randomIntFromInterval(0, 1)
    let s2 = randomIntFromInterval(0, 1)
    let sign1 = "+"
    let sign2 = "+"
    let mid = n4
    let ps = "Left"

    if(s1 == 1){
        sign1 = "-"
        ps = "Right"
    }

    if(s2 == 1){
        sign2 = "-"
        mid = -n4
    }

    let answerToDisplay = (
      <span>
        <span>{`$ \\text{Amplitude} = ${n1} $`}</span>
        <br/>
        <span>{`$ \\text{Midline} = ${mid} $`}</span>
        <br/>
        <span>{`$ \\text{Frequency} = ${f1} $`}</span>
        <br/>
        <span>{`$ \\text{Period} = T = ${f2} $`}</span>
        <br/>
        <span>{`$ \\text{Quarter Period} = \\dfrac{T}{4} = ${f3} $`}</span>
        <br/>
        <span>{`$ \\text{Phase Shift} = \\text{${ps}} \\; ${f4} $`}</span>
        <br/>
      </span>
    )

    return {
      key: '3-tw',
      equation: `$ y = ${n1} \\text{csc}(${n2} \\pi x ${sign1} ${n3} \\pi) ${sign2} ${n4} $`,
      answer: answerToDisplay,
    }
  }

  function equationFormatter4() {
    let n1 = randomIntFromInterval(2, 9)
    let n2 = randomIntFromInterval(2, 9)
    let n3 = randomIntFromInterval(2, 9)
    let n4 = randomIntFromInterval(2, 9)

    let f1 = displayFraction(n2, 2)
    let f2 = displayFraction(2, n2)
    let f3 = displayFraction(1, 2*n2)
    let f4 = displayFraction(n3, n2)

    let s1 = randomIntFromInterval(0, 1)
    let s2 = randomIntFromInterval(0, 1)
    let sign1 = "+"
    let sign2 = "+"
    let mid = n4
    let ps = "Left"

    if(s1 == 1){
        sign1 = "-"
        ps = "Right"
    }

    if(s2 == 1){
        sign2 = "-"
        mid = -n4
    }

    let answerToDisplay = (
      <span>
        <span>{`$ \\text{Amplitude} = ${n1} $`}</span>
        <br/>
        <span>{`$ \\text{Midline} = ${mid} $`}</span>
        <br/>
        <span>{`$ \\text{Frequency} = ${f1} $`}</span>
        <br/>
        <span>{`$ \\text{Period} = T = ${f2} $`}</span>
        <br/>
        <span>{`$ \\text{Quarter Period} = \\dfrac{T}{4} = ${f3} $`}</span>
        <br/>
        <span>{`$ \\text{Phase Shift} = \\text{${ps}} \\; ${f4} $`}</span>
        <br/>
      </span>
    )

    return {
      key: '4-tw',
      equation: `$ y = ${n1} \\text{sec}(${n2} \\pi x ${sign1} ${n3} \\pi) ${sign2} ${n4} $`,
      answer: answerToDisplay,
    }
  }

  function equationFormatter5() {
    let n1 = randomIntFromInterval(2, 9)
    let n2 = randomIntFromInterval(2, 9)
    let n3 = randomIntFromInterval(2, 9)
    let n4 = randomIntFromInterval(2, 9)

    let f1 = displayFraction(n2, 1)
    let f2 = displayFraction(1, n2)
    let f3 = displayFraction(1, 4*n2)
    let f4 = displayFraction(n3, n2)

    let s1 = randomIntFromInterval(0, 1)
    let s2 = randomIntFromInterval(0, 1)
    let sign1 = "+"
    let sign2 = "+"
    let mid = n4
    let ps = "Left"

    if(s1 == 1){
        sign1 = "-"
        ps = "Right"
    }

    if(s2 == 1){
        sign2 = "-"
        mid = -n4
    }

    let answerToDisplay = (
      <span>
        <span>{`$ \\text{Amplitude} = ${n1} $`}</span>
        <br/>
        <span>{`$ \\text{Midline} = ${mid} $`}</span>
        <br/>
        <span>{`$ \\text{Frequency} = ${f1} $`}</span>
        <br/>
        <span>{`$ \\text{Period} = T = ${f2} $`}</span>
        <br/>
        <span>{`$ \\text{Quarter Period} = \\dfrac{T}{4} = ${f3} $`}</span>
        <br/>
        <span>{`$ \\text{Phase Shift} = \\text{${ps}} \\; ${f4} $`}</span>
        <br/>
      </span>
    )

    return {
      key: '5-tw',
      equation: `$ y = ${n1} \\text{tan}(${n2} \\pi x ${sign1} ${n3} \\pi) ${sign2} ${n4} $`,
      answer: answerToDisplay,
    }
  }

  function equationFormatter6() {
    let n1 = randomIntFromInterval(2, 9)
    let n2 = randomIntFromInterval(2, 9)
    let n3 = randomIntFromInterval(2, 9)
    let n4 = randomIntFromInterval(2, 9)
    let x3 = -n3
    let x4 = -n4

    let f1 = displayFraction(n2, 1)
    let f2 = displayFraction(1, n2)
    let f3 = displayFraction(1, 4*n2)
    let f4 = displayFraction(n3, n2)

    let s1 = randomIntFromInterval(0, 1)
    let s2 = randomIntFromInterval(0, 1)
    let sign1 = "+"
    let sign2 = "+"
    let mid = n4
    let ps = "Left"

    if(s1 == 1){
        sign1 = "-"
        ps = "Right"
    }

    if(s2 == 1){
        sign2 = "-"
        mid = -n4
    }

    let answerToDisplay = (
      <span>
        <span>{`$ \\text{Amplitude} = ${n1} $`}</span>
        <br/>
        <span>{`$ \\text{Midline} = ${mid} $`}</span>
        <br/>
        <span>{`$ \\text{Frequency} = ${f1} $`}</span>
        <br/>
        <span>{`$ \\text{Period} = T = ${f2} $`}</span>
        <br/>
        <span>{`$ \\text{Quarter Period} = \\dfrac{T}{4} = ${f3} $`}</span>
        <br/>
        <span>{`$ \\text{Phase Shift} = \\text{${ps}} \\; ${f4} $`}</span>
        <br/>
      </span>
    )

    return {
      key: '6-tw',
      equation: `$ y = ${n1} \\text{cot}(${n2} \\pi x ${sign1} ${n3} \\pi) ${sign2} ${n4} $`,
      answer: answerToDisplay,
    }
  }

  function helpText() {
    return `
      $$ \\textbf{Sin, Cos, Csc, Sec} $$
      \
      $$ y = a \\cdot \\text{sin} (bx + c) + d $$
      \
      $$ y = a \\cdot \\text{sin} \\Bigl( b \\Bigl( x + \\dfrac{c}{b} \\Bigr) \\Bigr) + d $$
      \
      $$ \\text{Amplitude} = |a|  $$    
      \
      $$ \\text{Midline} = d $$
      \
      $$ \\text{Frequency} = f = \\dfrac{|b|}{2 \\pi} $$
      \
      $$ \\text{Period} = T = \\dfrac{2 \\pi}{|b|} $$
      \
      $$ \\text{Quarter Period} = \\dfrac{T}{4} = \\dfrac{\\pi}{2|b|} $$
      \
      $$ \\text{Phase Shift} = \\text{Left} \\; \\dfrac{c}{b} $$
      \
      $$ \\textbf{Tan, Cot} $$
      \
      $$ y = a \\cdot \\text{tan} (bx - c) - d $$
      \
      $$ y = a \\cdot \\text{tan} \\Bigl( b \\Bigl( x - \\dfrac{c}{b} \\Bigr) \\Bigr) - d $$
      \
      $$ \\text{Amplitude} = |a|  $$    
      \
      $$ \\text{Midline} = -d $$
      \
      $$ \\text{Frequency} = f = \\dfrac{|b|}{\\pi} $$
      \
      $$ \\text{Period} = T = \\dfrac{\\pi}{|b|} $$
      \
      $$ \\text{Quarter Period} = \\dfrac{T}{4} = \\dfrac{\\pi}{4|b|} $$
      \
      $$ \\text{Phase Shift} = \\text{Right} \\; \\dfrac{c}{b} $$
      `
  }

  return (
    <div>

      <EquationLayout
        title={"Trigonometry Waves"}
        instructions={"Compute the Amplitude, Midline, Frequency, Period, Quarter Period, and Phase Shift."}
        formattedProblems={formattedProblems}
        viewAnswers={viewAnswers}
      />

      <GenerateButtons
        generateProblems={equationMaker}
        setShowHideAnswers={setViewAnswers}
        viewAnswers={viewAnswers}
        helpModalContent={{title: "Waves Help", helpTextFunc: helpText}}
      />
    </div>
  );
}